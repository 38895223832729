import { FC, ReactNode, useCallback } from "react";
import React, { createContext, useEffect } from "react";
import { IContent } from "src/types/IContent";
import { IUser } from "src/types/IUser";
import { ACTION_TYPES } from "./actions";
import services from "./services";
import useAuthState from "./state";
import { AuthContextValue, initialAuthState } from "./types";

const AuthContext = createContext<AuthContextValue>(initialAuthState);

interface ProviderProps {
  children: ReactNode;
}

const AuthProvider: FC<ProviderProps> = ({ children }: ProviderProps) => {
  const { state, dispatch } = useAuthState();

  const initUser = useCallback(
    (
      isAuthenticated: boolean,
      user: IUser,
      navigationContent?: IContent
    ): void => {
      dispatch({
        type: ACTION_TYPES.INITIALIZE,
        payload: {
          isAuthenticated,
          user,
          navigationContent,
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    try {
      const identityToken = window.localStorage.getItem("IdentityToken");
      if (identityToken && services.isValidToken(identityToken)) {
        // if (window.API_URL === undefined) {
        const identitytoken = window.localStorage.getItem("IdentityToken");
        const refreshtoken = window.localStorage.getItem("RefreshToken");
        const region = window.localStorage.getItem("Region");
        services.setSession(identitytoken, refreshtoken, region);
        // }
        Promise.all([services.getUser(), services.getNavigationContent()])
          .then(([user, navigationContent]) => {
            services.changeLanguage(user?.UserLanguage ?? "E");
            initUser(true, user, navigationContent);
          })
          .catch((error) => {
            console.log(error);
            initUser(false, null, null);
            services.onError(error);
          });
      } else {
        initUser(false, null, null);
      }
    } catch (error) {
      initUser(false, null, null);
      services.onError(error);
    }
  }, [dispatch, initUser]);

  return (
    <AuthContext.Provider value={{ ...state }}>{children}</AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
