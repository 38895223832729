import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react";
import { axiosInstance } from "src/utils";
import { snackActions } from "src/utils/SnackbarUtils";
import { IUser } from "../types/IUser";

interface ProfileState {
  open: boolean;
  user: any;
  avatar: any;
  isChangingPassword: boolean;
}

interface ProfileContextValue {
  state?: ProfileState | null;
  dispatch?: (value) => void;
  getUser?: () => void;
  updateUser?: (user: IUser) => void;
  getAvatar?: (user: IUser) => void;
  updateAvatar?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  deleteAvatar?: () => void;
}

const ProfileContext = createContext<ProfileContextValue>({});
const useProfile = (): ProfileContextValue => useContext(ProfileContext);

const reducer = (state: ProfileState, action): ProfileState => {
  let newState = state;
  switch (action.type) {
    case "SET_OPEN": {
      const { user } = action.payload;
      newState = { ...state, user, open: true };
      break;
    }
    case "SET_CLOSED": {
      newState = {
        ...state,
        open: false,
        isChangingPassword: false,
      };
      break;
    }
    case "GET_USER": {
      const { user } = action.payload;
      newState = { ...state, user };
      break;
    }
    case "GET_AVATAR": {
      const { avatar } = action.payload;
      newState = { ...state, avatar };
      break;
    }
    case "CHANGE_PASSWORD": {
      newState = { ...state, isChangingPassword: !state.isChangingPassword };
      break;
    }
    case "UPDATE_USER": {
      const { user } = action.payload;
      newState = { ...state, user };
      break;
    }
    default: {
      return state;
    }
  }
  return newState;
};

interface ProfileProps {
  children: ReactNode;
}

const ProfileProvider: FC<ProfileProps> = ({ children }: ProfileProps) => {
  const initialState: ProfileState = {
    open: false,
    isChangingPassword: false,
    avatar: null,
    user: {
      EntityState: "",
      PropertiesChanged: [],
      AppUserID: 0,
      Email: "",
      FullName: "",
      Language: "",
      EmployeeID: null,
      Active: "",
      EmployeeName: "",
      PhoneNumber: "",
      EntityID: "",
      FlexNumber: "",
      BusinessUnit: "",
      BusinessUnitName: "",
      ManagerName: "",
      Company: "",
      CompanyName: "",
      Admin: null,
      Manager: null,
      ADPExport: null,
      FlexExport: null,
      Reports: null,
    },
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const getUser = useCallback(() => {
    dispatch({
      type: "IS_LOADING",
      payload: { parameter: "user", status: true },
    });

    return axiosInstance
      .get(`/`)
      .then((response) => {
        let user = response.data.ResponseData;
        if (!user) user = state.user;
        dispatch({
          type: "GET_USER",
          payload: {
            user,
          },
        });
        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "user", status: false },
        });
      })
      .catch((err) => {
        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "user", status: false },
        });
      });
  }, [state.user]);

  const updateUser = useCallback((user) => {
    return axiosInstance
      .put(`/User/UpdateUser`, user)
      .then(() => dispatch({ type: "UPDATE_USER", payload: { user } }))
      .catch((err) => {
        snackActions.error("Failed to update user");
      });
  }, []);

  const getAvatar = useCallback((user) => {
    return axiosInstance
      .get(`/`)
      .then((response) => {
        let avatar = response.data.ResponseData;
        dispatch({
          type: "GET_AVATAR",
          payload: {
            avatar,
          },
        });
      })
      .catch((err) => {});
  }, []);

  const updateAvatar = useCallback(async (event) => {
    return axiosInstance
      .put(
        `/User/UpdateAvatar`,
        Buffer.from(await event.target.files[0].arrayBuffer()),
        {
          headers: {
            "Content-Type": "image/jpeg",
          },
        }
      )
      .then((response) => {
        let avatar = response.data.ResponseData;
        dispatch({
          type: "GET_AVATAR",
          payload: {
            avatar,
          },
        });
      })
      .catch((err) => {});
  }, []);

  const deleteAvatar = useCallback(() => {
    axiosInstance
      .delete(`/User/DeleteAvatar`)
      .then((res) => {
        snackActions.success("Avatar deleted");
      })
      .catch((err) => {
        snackActions.error("Failed to delete avatar");
      });
  }, []);

  const value = useMemo(
    () => ({
      state,
      dispatch,
      getUser,
      updateUser,
      getAvatar,
      updateAvatar,
      deleteAvatar,
    }),
    [
      state,
      dispatch,
      getUser,
      updateUser,
      getAvatar,
      updateAvatar,
      deleteAvatar,
    ]
  );

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
};

export { ProfileProvider, useProfile };
