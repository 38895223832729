const E = {
  ok: "Ok",
  cancel: "Cancel",
  submit: "Submit",
  proceed: "Proceed",
  proceedtoQueue: "Proceed to Receipt Queue",
  edit: "Edit",
  update: "Update",
  delete: "Delete",
  undo: "Undo",
  sampleReception: "Sample Reception",
  logout: "Logout",
  changePassword: "CHANGE PASSWORD",
  logInToBv: "Log in to Bureau Veritas Sample Reception",
  enterYourInformation: "Please enter your information",
  resetYourPassword: "Reset your password",
  enterEmailBelow:
    "Please enter your email below and we will send you a new password",
  passwordEmailed: "Your password has been emailed to you",
  returnToLogin: "Return To Login",
  emailAddress: "Email Address",
  emailValidation: "Must be a valid email",
  password: "Password",
  region: "Region",
  login: "Login",
  resetPassword: "Reset password?",
  yearPlaceholder: "YYYY",
  monthPlaceholder: "MM",
  dayPlaceholder: "DD",
  datePlaceholder: "YYYY-MM-DD",
  timePlaceholder: "HH:mm",
  noData: "No Data",
  cocOcrFiles: "COC and OCR Files",
  cocFiles: "COC Files",
  receiptOcr: "Receipt OCR",
  attachCocFile: "Attach COC File",
  loadData: "Load Data",
  dataLoaded: "Data Loaded",
  sampleNo: "Sample No.",
  sampleInfo: "Sample Info",
  bottles: "Bottles",
  addBottle: "Add Bottle",
  saveAndClose: "Save and Close",
  saveAndNext: "Save and Next",
  clearFilters: "Clear Filters",
  filters: "Filter...",
  showColumnChooser: "Show Column Chooser",
  previousMonth: "Previous Month",
  nextMonth: "Next Month",
  open: "Open",
  close: "Close",
  clear: "Clear",
  openNextView: "open next view",
  openPreviousView: "open previous view",
  validationMessage: "is required",
  selected: "Selected",
  actr: "ACTR",
  changePasswordScreen: {
    changePasswordHeader: "CHANGE PASSWORD",
    temporaryPassword: "Temporary or Current Password",
    newPassword: "New Password",
    passwordRequirements: "Password Requirements",
    charLimit: "8 to 15 characters",
    upperCase: "1 upper case letter",
    lowerCase: "1 lower case letter",
    number: "1 number",
    specialChar: "1 special character (limited to ! @ $ % & * ?)",
    savePassword: "Save Password",
    newPasswordReq: "New password does not meet the requirements.",
  },
};

const F = {
  ok: "D'Accord",
  cancel: "Annuler",
  submit: "Soumettre",
  proceed: "Procéder",
  proceedtoQueue: "Passer à la file d'attente des reçus",
  edit: "Éditer",
  update: "Mise à jour",
  delete: "Effacer",
  undo: "Annuler",
  sampleReception: "Réception d'échantillons",
  logout: "Déconnexion",
  changePassword: "CHANGER LE MOT DE PASSE",
  logInToBv: "Connectez-vous à la réception d'échantillons",
  enterYourInformation: "Veuillez entrer vos informations",
  resetYourPassword: "Réinitialisez votre mot de passe",
  enterEmailBelow:
    "Veuillez saisir votre adresse courriel ci-dessous et nous vous enverrons un nouveau mot de passe.",
  passwordEmailed: "Votre mot de passe vous a été envoyé par courriel.",
  emailAddress: "Adresse courriel",
  emailValidation: "Doit être une adresse courriel valide",
  returnToLogin: "Retour à la connexion",
  password: "Mot de passe",
  region: "Sélectionnez une région",
  login: "Connexion",
  resetPassword: "Réinitialiser le mot de passe",
  yearPlaceholder: "AAAA",
  monthPlaceholder: "MM",
  dayPlaceholder: "JJ",
  datePlaceholder: "AAAA-MM-JJ",
  timePlaceholder: "HH:mm",
  noData: "Aucun Donnée",
  cocOcrFiles: " Fichiers CDR et ROC",
  cocFiles: "Fichiers CDR",
  receiptOcr: " ROC de reçu",
  attachCocFile: "Attacher un fichier CDR",
  loadData: "Charger les données",
  dataLoaded: "Données chargées",
  sampleNo: "Numero d'échantillon",
  sampleInfo: "Information d'échantillon",
  bottles: "Bouteilles",
  addBottle: "Ajouter une bouteille",
  saveAndClose: "Sauvegarder et fermer",
  saveAndNext: "Enregistrer et suivant",
  clearFilters: "Effacer les filtres",
  filters: "Filtre...",
  showColumnChooser: "Afficher le sélecteur de colonnes",
  previousMonth: "Mois précédent",
  nextMonth: "Mois prochain",
  open: "Ouvrir",
  close: "Fermer",
  clear: "Supprimer",
  openNextView: "ouvrir la vue suivante",
  openPreviousView: "ouvrir la vue précédente",
  validationMessage: "est obligatoire",
  selected: "Sélectionné",
  actr: "EDGS",
  changePasswordScreen: {
    changePasswordHeader: "CHANGER LE MOT DE PASSE",
    temporaryPassword: "Mot de passe temporaire ou ancien",
    newPassword: "Nouveau mot de passe",
    passwordRequirements: "Exigences relatives au mot de passe.",
    charLimit: " 8 à 15 caractères",
    upperCase: " 1 lettre majuscule",
    lowerCase: " 1 lettre minuscule",
    number: " 1 chiffre",
    specialChar: " 1 caractère special (limité à ! @ $ % & * ?)",
    savePassword: "Enregistrer le mot de passe",
    newPasswordReq: "Le nouveau mot de passe ne répond pas aux exigences.",
  },
};

const resources = { E, F };

export default resources;
